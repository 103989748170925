.realEstate-card {
    height: fit-content;
    width: 20rem;
    border: 1px solid black;
    border-radius: 5px;
    background-color: rgb(230, 230, 230, 0.95);
    box-shadow: 3px 2px 1px 1px rgba(0, 0, 0, 0.4);
    padding: 1rem 1rem;
    overflow: hidden;
    
  }
  .realEstate-card-body-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .realEstate-card-body-container > img {
    aspect-ratio: 4/4;
    width: 100%;
    height: auto;
    max-width: 20rem;
    max-height: 11rem;
    border-radius: 5px;
    border: 1px solid black;
    margin-top: 1rem;
  }
  .realEstate-card-header {
    text-align: center;
  }
  .realEstate-card-body {
    padding: 1rem;
  }
  .product-info{
    font-weight: bold;
  }
  .status{
    color: var(--color-realEstate-secondary);
  }
  .readMore-link{
    margin: 1rem;
    border-radius: 5px;
    background-color: var(--color-headerFooter);
    text-align: center;
  }
  .readMore-link > a{
    pad: 1rem;
    color: var(--color-realEstate-secondary);
    text-decoration: none;
    transition: 200ms ease-in-out;

  }
  .readMore-link:hover > a{
    color: white;
    transition: 200ms ease-in-out;
    transition: 200ms ease-in-out;
  }
  
  @media only screen and (max-width: 750px) {
    .realEstate-card-body-container {
      flex-direction: column;
      gap: 1rem;
      padding-top: 1rem;
    }
    .realEstate-card {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    
    .realEstate-card-top-center {
      height: fit-content;
    }
  }
  
  @media only screen and (max-width: 400px) {
    .realEstate-card{
      padding: 5px;
    }
  }
  