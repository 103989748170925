.header {
  width: 100%;
  height: 3rem;
  background-color: var(--color-headerFooter);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.75rem;
  z-index: 999;
}
.logo {
  width: 2.25rem;
  height: 2.25rem;
  border: 1px solid grey;
}
.nav-right {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.nav-links {
  text-decoration: none;
  color: var(--color-text);
  padding: 5px;
  border-radius: 5px;
  transition: 200ms ease-in-out;
}
.nav-links:hover {
  color: white;
  transition: 200ms ease-in-out;
}
#pharma-icon {
  color: "#FFD200";
}

.nav-icon {
  margin: 0 3px 1px 0;
}
.footer {
  width: 100%;
  height: fit-content;
  background-color: var(--color-headerFooter);
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 1rem 3rem;
  position: relative;
  bottom: 0;
  z-index: 999;
  margin-top: -5px;
}
.footer > div {
  display: flex;
  flex-direction: column;
}
.footer-logo{
  height: 6rem;
  width: 6rem;
  border: 1px solid black;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}
.footer-icon {
  margin: 0 3px 1px 0;
}
.quick-access-links{
  text-decoration: none;
  color: black;
}
.footer-headers{
  margin-bottom: 1rem;
}
.copy-contact {
  padding: 3px;
  border-radius: 5px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 200 ease-in-out;
}
.copy-contact:active {
  background-color: green;
  transition: 0;
}
.footer-footer{
  border-top: 1px solid black;
  height: 2rem;
  background-color: rgb(148, 176, 201);
  text-align: center;
}
@media only screen and (max-width: 700px) {
  .nav-right {
    width: 80%;
  }
  .footer{
    padding: 1rem 2rem;
  }
}
@media only screen and (max-width: 400px) {
  .nav-right {
    font-size: 0.8rem;
  }
  .logo {
    width: 1.75rem;
    height: 1.75rem;
    border: 1px solid grey;
  }
  .footer{
    padding: 1rem 0;
  }
}
