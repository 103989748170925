.card {
  height: fit-content;
  min-width: calc(100vw - 6rem);
  border: 1px solid black;
  border-radius: 5px;
  background-color: rgb(230, 230, 230, 0.95);
  box-shadow: 3px 2px 1px 1px rgba(0, 0, 0, 0.4);
  padding: 1rem 2rem;
  padding-bottom: 4rem;
  position: relative;
  overflow: hidden;
}
.card-top > img {
  width: 30%;
  height: auto;
  max-width: 20rem;
  max-height: 11rem;
  border-radius: 5px;
  border: 1px solid black;
}
.card-top {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1rem;
  height: 10rem;
}
.card-top-center {
  height: 10rem;
  padding: 1rem;
}
.card-link {
  height: 6rem;
  width: 3rem;
  position: absolute;
  right: 2rem;
  top: -3rem;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  padding: 1rem;
  border: 2px dashed black;
  border-radius: 0 0 3rem 3rem;
  background-color: var(--color-pharma-secondary);

  transition: 200ms ease-in-out;
}
.card-link:hover {
  top: -2px;
  transition: 200ms ease-in-out;
}
.card-header {
  text-align: center;
}
.card-body {
  padding: 1rem;
}
.body-headers {
  margin: 0.5rem;
}

@media only screen and (max-width: 750px) {
  .card-top > img {
    width: 65%;
  }
  .card {
    min-width: calc(100vw - 2rem);
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .card-top {
    flex-direction: column;
    height: fit-content;
    padding: 1rem 0;
  }
  .card-top-center {
    height: fit-content;
  }
}

@media only screen and (max-width: 400px) {
  .card-top > img {
    width: 70%;
  }
  .card {
    min-width: calc(100vw - 1rem);
    padding: 5px;
  }
}
