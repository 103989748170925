.realEstate-aboutOwner-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
    border-radius: 5px;
    height: fit-content;
    min-width: calc(100vw - 6rem);
    padding: 1rem 2rem;
    padding-bottom: 4rem;
    position: relative;
    overflow: hidden;
  }
  .realEstate-faceShot{
      width: 8rem;
      height: 10rem;
      border-radius: 50%;
      border: 1px solid black;
  }
  .realEstate-about-owner{
      border-radius: 5px;
      border: 1px solid black;
      background-color: rgb(230, 230, 230, 0.95);
      padding: 2rem;
    box-shadow: 3px 2px 1px 1px rgba(0, 0, 0, 0.4);
    line-height: 1.4rem;
  
  }
 .copy-email{
     cursor: pointer;
     position:relative;
 }
 .copy-email::after{
    cursor:default;
 }
 .copy-email:active::after{
     opacity: 1;
     animation: flash 0.1s forwards;
     
 }
 @keyframes flash {
     0% {
         opacity: 0;
     }
     100% {
         opacity: 1;
     }
 }
 .copy-email::after{
     content: "copied!";
     position:absolute;
     background-color: white;
     font-size: 1.5rem;
     padding: 0.5rem;
     border-radius: 5px;
     opacity: 0;
     width: fit-content;
 }
  @media only screen and (max-width: 700px){
      .realEstate-aboutOwner-container{
          flex-direction: column;
      }
      .realEstate-about-owner{
          padding: 1rem;
      }
  }