@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Oswald", sans-serif;
}
html {
  --color-pharma-primary: #8896bb;
  --color-pharma-secondary: #dc430f;
  --color-pharma-background: #f8f8f8;
  --color-realEstate-primary: #5baed1;
  --color-realEstate-secondary: #ce6542;
  --color-realEstate-background: #f8f8f8;
  --color-headerFooter: #c7c5dc;
  --color-grey: #bebebe;
  --color-text: #111116;
}
body,
#root {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
