.main {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}
.real-estate {
  object-fit: contain;
  object-position: center;
  width: 75%;
  z-index: 1;
  transition: 400ms ease-in-out;
  filter: brightness(80%);
}
.pharma {
  object-fit: contain;
  object-position: center;
  width: 75%;
  z-index: 1;
  transition: 400ms ease-in-out;
  filter: brightness(80%);
}

.real-estate:hover {
  transform: translateX(10%);
  transition: 400ms ease-in-out;
  filter: brightness(100%);
}
.pharma:hover {
  transform: translateX(-10%);
  transition: 400ms ease-in-out;
  filter: brightness(100%);
}
.real-estate:hover + .pharma {
  position: relative;
  transform: translateX(10%);
  transition: 400ms ease-in-out;

  filter: brightness(50%);
}

.real-estate-text,
.pharma-text {
  text-align: center;
  position: absolute;
  padding: 1rem;
  border-radius: 5px;
  border: 2px solid black;
  left: 35%;
  background-color: var(--color-headerFooter);
  font-size: larger;
  transform: translateY(50vh);
}
.pharma-link,
.real-estate-link {
  text-decoration: none;
  color: black;
  transition: 200ms ease-in-out;
}
.pharma-link:hover,
.real-estate-link:hover {
  color: white;
  transition: 200ms ease-in-out;
}
@media only screen and (max-width: 1000px) {
  /* med-lg device and higher */
  .real-estate-text,
  .pharma-text {
    font-size: medium;
  }
  .pharma-text {
    left: 20%;
  }
}
@media only screen and (max-width: 600px) {
  /* small device */
  .real-estate-text,
  .pharma-text {
    font-size: small;
    padding: 0.5rem;
  }
  .pharma-text {
    left: 10%;
  }
}
@media only screen and (max-width: 400px) {
  /* x-small device just in case */
  .real-estate-text,
  .pharma-text {
    font-size: x-small;
    padding: 0.5rem;
  }
}
