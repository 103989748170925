.realEstate-main {
  width: 100%;
  height: fit-content;
}
.top-header > img {
  border: none;
}
.top-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-header {
  font-weight: bolder;
  display: flex;
  padding: 1rem;
  background-color: #63f5c9;
  border-radius: 5px;
  font-size: 1.5rem;
  border: 1px solid var(--color-realEstate-secondary);
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.realEstate-image-container {
  width: 100%;
  height: 90vh;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20rem;

  padding: 1rem 2rem;
  padding-bottom: 4rem;
}

.realEstate-image-text {
  min-width: 16rem;
  max-width: calc(100vw - 10px);
  padding: 1rem;
  font-size: 1.4rem;
  border-radius: 5px;

  background-color: var(--color-realEstate-primary);
  border: 1px solid black;

  transition: 200ms ease-in-out;
}
.realEstate-image-text:hover {
  transition: 200ms ease-in-out;
  border: 1px solid var(--color-realEstate-secondary);
}
.scrollTo {
  cursor: pointer;
  transition: 200ms ease-in-out;
}
.scrollTo:hover {
  color: var(--color-realEstate-secondary);
  transition: 200ms ease-in-out;
}

.realEstate-info {
  width: 100%;
  height: fit-content;
  padding: 2rem 1rem;
  background-color: var(--color-realEstate-background);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.realEstate-info > h1 {
  text-align: center;
  font-size: 3rem;

  margin: 3rem;
  color: var(--color-realEstate-secondary);
}

.realEstate-about-p {
  width: 90%;
  max-width: 70rem;
  margin-left: 3.5rem;
}

.realEstate-projects {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 3rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.realEstate-projects-grid{
  width: 90vw;
  display: grid;
  grid-template-columns: repeat(auto-fit, 20rem);
  justify-content: center;
  gap: 2rem;
}
.realEstate-projects > h1 {
  text-align: center;
  margin: 3rem;
  color: var(--color-realEstate-secondary);
}
@media only screen and (max-width: 500px) {
  .realEstate-image-text{
    padding: 0.5rem;
  }
  .realEstate-projects-grid{
    width: 98vw;
  }
}
@media only screen and (max-width: 400px) {
  .realEstate-image-container {
    gap: 5rem;
  }
  
}
@media only screen and (orientation: landscape) and (max-height: 500px) {
  .realEstate-image-text {
    margin-top: -70vh;
    font-size: 0.9rem;
  }
}
