.pharma-main {
  width: 100%;
  height: fit-content;
}
.top-header > img {
  border: none;
}
.top-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-header {
  font-weight: bolder;
  display: flex;
  padding: 1rem;
  background-color: #ffd100;
  border-radius: 5px;
  font-size: 1.5rem;
  border: 1px solid var(--color-pharma-secondary);
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.pharma-image-container {
  width: 100%;
  height: 90vh;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20rem;

  padding: 1rem;
}
/* colors if backgrounds change here */
/* colors if backgrounds change here */
/* colors if backgrounds change here */
/* colors if backgrounds change here */
.pharma-first-spacer {
  height: 2vh;
  width: 100%;
  background: linear-gradient(0.5turn, #ffcc01, #003470);
}
.pharma-second-spacer {
  height: 2vh;
  width: 100%;
  background: linear-gradient(0.5turn, #003470, #ffcc01);
}
.pharma-image-text {
  min-width: 16rem;
  max-width: calc(100vw - 10px);
  padding: 1rem;
  font-size: 1.4rem;

  border-radius: 5px;

  border: 1px solid var(--color-pharma-secondary);
  background-color: rgb(230, 230, 230, 0.3);

  transition: 200ms ease-in-out;
}
.pharma-image-text:hover {
  background-color: var(--color-pharma-primary);
  transition: 200ms ease-in-out;
}
.scrollTo {
  cursor: pointer;
  transition: 200ms ease-in-out;
}
.scrollTo:hover {
  color: var(--color-secondary);
  transition: 200ms ease-in-out;
}
.pharma-info {
  width: 100%;
  height: fit-content;
  padding: 2rem 1rem;
  background-color: var(--color-pharma-background);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.pharma-info > h1 {
  text-align: center;
  font-size: 3rem;
  
  margin: 3rem;
  color: var(--color-pharma-secondary);
}

.pharma-about-p {
  width: 90%;
  max-width: 70rem;
  margin-left: 3.5rem;
}

.pharma-products {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 3rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.pharma-products > h1 {
  text-align: center;
  margin: 3rem;
  color: var(--color-pharma-secondary);
}
@media only screen and (max-width: 400px) {
  .pharma-image-container {
    gap: 5rem;
  }
  
}
@media only screen and (orientation:landscape)
 and (max-height: 500px)
{
   .pharma-image-text {
     margin-top: -70vh;
     font-size: 0.9rem;
    
   }
}