.aboutOwner-container{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
  border-radius: 5px;
  height: fit-content;
  min-width: calc(100vw - 6rem);
  padding: 1rem 2rem;
  padding-bottom: 4rem;
  position: relative;
  overflow: hidden;
}
.faceShot{
    width: 8rem;
    height: 10rem;
    border-radius: 50%;
    border: 1px solid black;
}
.about-owner{
    border-radius: 5px;
    border: 1px solid black;
    background-color: rgb(230, 230, 230, 0.95);
    padding: 2rem;
  box-shadow: 3px 2px 1px 1px rgba(0, 0, 0, 0.4);
  line-height: 1.4rem;

}
.inline-emphesis{
    text-decoration: none;
    color: var(--color-pharma-secondary);
}
@media only screen and (max-width: 700px){
    .aboutOwner-container{
        flex-direction: column;
    }
    .about-owner{
        padding: 1rem;
    }
}